<template>
  <div class="d-flex flex-column">
    <v-btn
      :loading="isWorking"
      @click="getInvoices()"
    >Get disputed invoices</v-btn>
    <div class="mt-4 d-flex flex-column">
      <span>Result :</span>
      {{disputedInvoices}}
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
      isWorking: false,
      disputedInvoices: []
    }
  },
  methods: {
    async getInvoices (from) {
      this.isWorking = true
      await this.$http().get('/core/allocation/disputed-invoices')
        .then((r) => {
          this.disputedInvoices = r?.data
          this.isWorking = false
        })
        .catch(e => {
          this.isWorking = false
        })
    }
  }
}
</script>
